import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { usePageContext } from "../components/Context/pageContext";
import { Layout } from "../components/Layout/Layout";
import { SEO } from "../components/Seo";
import { AboutPageQuery, SitePageContext } from "../../graphql-types";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SubtitleImage } from "../components/Image/SubtiteImage";
import { AboutBlockContent } from "../components/BlockContent/AboutBlockContent";
import * as styles from "../styles/pages/about.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { AboutVideo } from "../components/Video/AboutVideo";

interface Props {
  data: AboutPageQuery;
}

const About: React.FC<Props> = ({ data }) => {
  const { lang } = usePageContext();
  const { page } = data;

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata} />
      <PageTitle content={page?.content?._rawTitle[lang]} />

      <div className={styles.wrapper}>
        <SubtitleImage
          className={styles.image}
          image={page?.content?.image?.asset?.gatsbyImageData}
          alt={"concave"}
        />

        <div className={styles.text}>
          <AboutBlockContent blocks={page?.content?._rawContent[lang]} />
        </div>
      </div>
      <div className={styles.videoWrapper}>
        {/* <AboutVideo video={page?.content?.videoUrl} /> */}
      </div>
    </Layout>
  );
};

export default About;

export const QUERY = graphql`
  query AboutPage {
    page: sanityAboutPage {
      content {
        _rawTitle
        _rawContent
        image {
          asset {
            gatsbyImageData
          }
        }
        videoUrl
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }
  }
`;
