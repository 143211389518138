import React from "react";
import * as styles from "./pagetitle.module.scss";

interface Props {
  content: string;
}

export const PageTitle: React.FC<Props> = React.memo(({ content }) => {
  return <div className={styles.text}>{content}</div>;
});
