import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./subtitleImage.module.scss";

interface Props {
  image: any;
  alt: string;
  className?: string;
}

export const SubtitleImage: React.FC<Props> = ({ image, alt, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <GatsbyImage image={image} alt={alt || ""} />
    </div>
  );
};
